@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --primaryBackground: #212222;
  --secondaryBackground: #2f3030;
  --primaryColor: #e0a80d;
  --greyDark: #7d8792;
  --headingColor: #2d2e2e;
  --white: #fff;
  --black: #000;
  --sliver: #bac8d3;
}
body {
  background-color: var(--headingColor);
  color: var(--white);
  font-family: "Poppins", sans-serif;
}
a {
  color: var(--greyDark);
  text-decoration: none;
  transition: 0.5s;
}
a:hover {
  color: var(--primaryColor);
}
p {
  color: var(--sliver);
  font-size: 15px;
  line-height: 28px;
  margin: 20px 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}
.container {
  max-width: 100%;
  padding: 0;
  margin: 0;
}
.flexsb {
  display: flex;
  justify-content: space-between;
}
button {
  border: none;
  outline: none;
  background: none;
  font-size: 17px;
  background-color: var(--primaryColor);
  color: var(--white);
  padding: 15px 50px;
  border-radius: 50px;
  margin-top: 20px;
  cursor: pointer;
}

a.primaryBtn {
  border: none;
  outline: none;
  font-size: 17px;
  color: var(--white);
  padding: 15px 50px;
  margin-top: 20px;
  border-radius: 50px;
  cursor: pointer;
  background: none;
  border: 2px solid var(--primaryColor);
}


/*---------header-------------*/
header {
  background-color: var(--headingColor);
  height: 10vh;
  line-height: 10vh;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
header .nav {
  margin-right: 1rem;
}
header a {
  margin-left: 20px;
  font-size: 1.5vw;
}
.logo-img {
  cursor: pointer;
  width: auto;
  height: 35px;
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.toggle {
  cursor: pointer;
  color: var(--white);
  margin-top: -0.5rem;
  margin-right: -2.5rem;
  display: none;
}
.toggle .icon {
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  header .nav {
    display: none;
  }
  header a.menu-item {
    width: 100%;
    display: block;
    color: var(--white);
    font-size: 3.25vw; 
    text-align: center;
    padding: 10px 0; 
  }
  .toggle {
    display: block;
    font-size: 25px;
    background: none;
  }
  .hideMenu {
    display: block;
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: var(--headingColor);
  }
}
@media screen and (max-width: 500px) {
  header a.menu-item {
    font-size: 4vw; 
  }
}
/*---------header-------------*/

/*---------section-------------*/
section {
  width: 100%;
  height: 120vh;
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
}
/*---------section-------------*/

/*---------hero-------------*/
.hero {
  position: relative;
}
.hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../public/images/home-bg.png");
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
  width: 100%;
  height: 110vh;
}
.heroContent {
  max-width: 50%;
  margin: auto;
  text-align: center;
}
.hero h1 {
  font-size: 50px;
  font-weight: 600;
  color: var(--primaryColor);
}
/*---------hero-------------*/

/*---------heading-------------*/
.heading {
  font-size: 20px;
  color: var(--primaryColor);
  font-weight: 600;
  text-align: center;
  margin-bottom: 60px;
}
/*---------heading-------------*/

/*---------about-------------*/
.about .primaryBtn {
  margin-left: 20px;
}
.about .heading {
  text-align: left;
  margin: 0;
}
.about .flex {
  display: flex;
}
.about .left {
  width: 40%;
  margin-left: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
}
.about .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  opacity: 0.2;
}
.about .right {
  width: 60%;
  height: 100%;
}
.about p {
  margin-bottom: 2rem;
}
.about p,
.about a {
  font-size: 1.5vw;
}
/* .about a {
  border: var(--primaryColor);
  outline: none;
  color: var(--white);
  padding: 15px 50px;
  border-radius: 50px;
  cursor: pointer;
  background: none;
  border: 2px solid var(--primaryColor);
} */
/*---------about-------------*/

/*---------services-------------*/
.services .box {
  background-color: var(--primaryBackground);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  font-size: 1.5vw;
}
.services i {
  font-size: 1.5vw; 
  color: var(--primaryColor);
}
.services h3 {
  margin-top: 20px;
}
.services p {
  font-size: 1.5vw;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-left: 20px;
  margin-right: 20px;
  place-items: center;
  text-align: center;
}
.grid4 .box {
  height: 100%;
}
/*---------services-------------*/

/*---------cooperation-------------*/
.cooperation {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5vh;
}
.cooperation::after {
  background-image: url("../public/images/cooperation-bg.png");
  height: 100vh;
}
.cooperation .box,
.cooperation .box p {
  font-size: 1.5vw;
}
.cooperation .introduction,
.cooperation .summary,
.cooperation .title-box {
  background-color: var(--primaryBackground);
  border-radius: 10px;
  margin-top: 20px; 
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1.5vw;
}
.cooperation .title-text {
  font-size: 1.5vw;
}
.cooperation .intro-text,
.cooperation .intro-text2,
.cooperation .summary-text {
  padding: 15px 15px;
  font-size: 1.5vw;
}
.cooperation i {
  background-color: var(--white);
  font-size: 1.5vw;
  width: 40px;
  height: 40px;
  padding: 8px;
  margin: 10px 0;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  color: var(--primaryColor);
}
.cooperation .img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  margin-bottom: 1rem;
  border: 3px solid var(--primaryColor);
}
.cooperation img {
  width: 100px;
  height: 100px;
  background-size: cover;
  border-radius: 50%;
  object-fit: cover;
}
label {
  color: var(--primaryColor);
  margin: 10px 0;
  display: inline-block;
  font-size: 1.5vw;
}
/*---------cooperation-------------*/

/*---------team-------------*/
.team {
  font-size: 1.5vw;
}
.team .content {
  display: flex;
  flex-wrap: wrap; 
  justify-content: flex-start; 
  gap: 15px;
}
.team .box {
  flex: 0 1 calc(20% - 15px); 
  background-color: var(--primaryBackground);
  border-radius: 10px;
  margin-top: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team img {
  width: 100%;
  max-width: 100%;
  height: 150px;
  border-radius: 10px 10px 0 0;
}
.team .introduction,
.team .box {
  background-color: var(--primaryBackground);
  border-radius: 10px;
}
.team .introduction {
  max-width: auto;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team .text {
  padding: 10px 25px;
}
.team .text p{
  font-size: 1.5vw;
}
.team .intro-text,
.team .intro-text2 {
  padding: 7px 25px;
  text-align: center;
  font-size: 1.5vw;
}
.team .title-text {
  text-align: center;
  margin-top: 15px;
}
.team .content {
  display: flex;
  justify-content: center;
  gap: 10px;
}
/*---------team-------------*/

/*---------contact-------------*/
.contact {
  margin: 20px 0;
}
.contact .box p{
  font-size: 1.25vw;
}
.contact .box .p1,
.contact .box .p2,
.contact .box .p3,
.contact .box .p4,
.contact .box .p5,
.contact .box .p6 {
  margin-bottom: -1rem;
}
.contact .right,
.contact .left {
  margin-left: auto;
  margin-right: auto;
}
.contact .flex input:nth-child(1) {
  margin-right: 10px;
}
.contact button,
.contact textarea,
.contact input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 1.25vw;
}
/*---------contact-------------*/

/*---------footer-------------*/
footer {
  text-align: center;
  background-color: var(--primaryBackground);
  padding: 70px;
  margin-top: 50px;
}
footer i {
  margin: 5px;
  color: var(--primaryColor);
}
/*---------footer-------------*/

/*---------media-------------*/
@media screen and (max-width: 990px) {
  header a {
    margin-left: 25px;
  }
  .grid4 {
    grid-template-columns: repeat(2, 1fr); 
    grid-gap: 10px; 
  }
  .services .box { 
    width: 100%; 
  }
}

@media screen and (max-width: 907px) {
  .team {
    margin-top: 20rem;
  }

  .team .content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }

  .team .content .box {
    display: flex;
    flex-direction: row;
  }

  .team .content .img {
    width: 30%;
    max-width: 200px;
    height: 150px;
  }

  .team .content img {
    border-radius: 10px 0 0 10px;
    margin-top: 0px;
    height: 150px;
  }

  .team .content .text {
    width: 65%;
    margin-left: 20px;
  }

  .team .content .text h3,
  .team .content .text p {
    margin: 0;
  }
  .cooperation {
    margin-top: 20rem;
  }
}

@media screen and (max-width: 768px) {
  .hero,
  .hero::after {
    height: 900px;
  }
  .heroContent {
    max-width: 80%;
  }
  .hero .fontSize {
    font-size: 20px;
  }
  .hero h1 {
    font-size: 40px;
  }
  .hero p {
    font-size: 15px;
  }
  section {
    height: 100%;
    padding-top: 50px;
  }
  .about p,
  .about a,
  .services .box,
  .services i,
  .services p,
  .cooperation .box,
  .cooperation .box p,
  .cooperation .introduction,
  .cooperation .summary,
  .cooperation .title-box,
  .cooperation .title-text,
  .cooperation .intro-text,
  .cooperation .intro-text2,
  .cooperation .summary-text,
  .cooperation i,
  label,
  .team,
  .team .text p,
  .team .intro-text,
  .team .intro-text2,
  .contact .box p {
  font-size: 2.25vw;
  }
  .contact button,
  .contact textarea,
  .contact input {
    font-size: 2vw;
  }
  .team,
  .cooperation {
    margin-top: 0;
  }
  .team .content .img {
    min-width: 180px;
    height: 136px;
  }
  .team .content img {
    height: 136px;
  }
  .team .content .text {
    text-align: center;
  }
  .contact .flexsb,
  .about .flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .about .flex {
    justify-content: center;
    text-align: center;
  }
  .about .heading {
    text-align: center;
  }
  .about .left {
    width: 60%;
  }
  .about .right {
    width: 90%;
  }
  footer {
    padding: 20px;
    margin-top: 25px;
  }
  footer i {
    margin: 2px;
  }  
}
@media screen and (max-width: 524px) {
  .team .content .img {
    min-width: 190px;
    height: 161px;
  }
  .team .content img {
    height: 161px;
  }
  .team .content .text {
    margin-left: 0;
  }
}
@media screen and (max-width: 450px) {
  .hero,
  .hero::after {
    height: 740px;
  }
  .hero h1 {
    font-size: 32.5px; 
  }
  .hero p {
    font-size: 15px; 
  }
  .about p,
  .about a,
  .services .box,
  .services i,
  .services p,
  .cooperation .box,
  .cooperation .box p,
  .cooperation .introduction,
  .cooperation .summary,
  .cooperation .title-box,
  .cooperation .title-text,
  .cooperation .intro-text,
  .cooperation .intro-text2,
  .cooperation .summary-text,
  .cooperation i,
  label,
  .team,
  .team .text p,
  .team .intro-text,
  .team .intro-text2,
  .contact .box p {
  font-size: 3vw;
  }
  .about .heading {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .heading {
    margin-bottom: 1.5rem;
  }  
  .about p {
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
  }
  .about .right .button {
    margin-top: 4rem;
  }
  .services .box {
    padding: 5px;
    }
  .services .box i,
  .services .box h3 {
    margin-top: 0.25rem;
  }
  .services .box h3,
  .services .box p {
    margin-bottom: -0.75rem;
  }
  .services .box p {
    margin-bottom: 0.25rem;
  }
  .team .intro-text {
    margin-top: 0.25rem;
    margin-bottom: 0rem;
  }
  .team .intro-text2 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .team .title-box .title{
    margin-bottom: 1rem;
  }
  .team .content .box {
    display: flex;
    flex-direction: column;     
    align-items: center;
    text-align: center;
    width: 275px;
    margin: 0 auto;
    padding: 0px;
  }
  .team .content .text {
    text-align: center;
    width: 100%; 
    margin-left: 0.20rem;
  }
  .team .content .img {
    min-width: 275px;
    height: 170px;
  }
  .team .content img{
  border-radius: 10px 10px 0 0;
  height: 165px;
  }
  .cooperation .intro-text,
  .cooperation .intro-text2,
  .cooperation .summary-text {
  padding: 5px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 0.25px;
  }
  .cooperation .introduction {
    margin-bottom: 1rem;
  }
  .cooperation .box {
    margin-bottom: -0.65rem;
  }
  .contact .flex {
    width: 95%;
    margin-left: 10px;
  }
  .contact input,
  .contact button,
  .contact textarea{
    font-size: 2.5vw;
    width: 95%; 
    padding: 15px;
    border: none;
    outline: none;
    margin: 10px auto; 
    border-radius: 5px;
  }
  .contact button {
    margin-bottom: 2rem;
  }
  footer {
    padding: 5px; 
    margin-top: 25px;
    text-align: center; 
  }
}
/*---------media-------------*/
.slick-prev,
.slick-next {
  /* font-size: 1.5vw; */
  color: var(--primaryColor);
  background-color: transparent;
  border: none;
  z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
  color: var(--white); 
  background-color: var(--primaryColor);
  transition: background-color 0.3s, color 0.3s;
}
.slick-prev {
    left: 20px; 
  }
  
.slick-next {
    right: 20px; 
}
